import { DialogRef } from '@angular/cdk/dialog';
import { ColumnSelectionV3 } from 'src/app/shared/components/design-system/data-table-v2/components/column-view-v3/model/column-view-v3.model';
import { PageEventV2 } from 'src/app/shared/components/design-system/data-table-v2/components/paginator/paginator.component';
import { Sorted } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ProposedBuyOrder } from 'src/app/shared/models/buy-orders/v2/proposed-buy-orders-v2.model';

export class LoadProposedOrdersV2 {
  static readonly type = '[Proposed Orders] Load Proposed Orders V2';
}

export class InitializeProposedOrders {
  static readonly type = '[Proposed Orders] Initialize Proposed Orders';
}

export class Order {
  static readonly type = '[Proposed Orders] Order';

  constructor(public buyorder: ProposedBuyOrder) {}
}

export class LoseFocus {
  static readonly type = '[Proposed Orders] Lose Focus';
}

export class BuyNow {
  static readonly type = '[Proposed Orders] Buy Now';

  constructor(public buyorder: ProposedBuyOrder) {}
}

export class ViewOrder {
  static readonly type = '[Proposed Orders] View Order';

  constructor(public buyorder: ProposedBuyOrder) {}
}

export class SkipOrderMoment {
  static readonly type = '[Proposed Orders] Skip Order Moment';

  constructor(
    public orderMomentId: string,
    public orderMomentUUID: string,
    public skipped: boolean,
    public dialogRef: DialogRef<any>,
    public reroute: boolean = false
  ) {}
}

export class IgnoreSupplier {
  static readonly type = '[Proposed Orders] Ignore Supplier';

  constructor(
    public supplierUUID: string,
    public ignored: boolean,
    public dialogRef: DialogRef<any>,
    public reroute: boolean = false
  ) {}
}

export class AddSearchParam {
  static readonly type = '[Proposed Orders] Add Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSearchParam {
  static readonly type = '[Proposed Orders] Remove Search Param';

  constructor(public param: string | null) {}
}

export class AddFilterParam {
  static readonly type = '[Proposed Orders] Add Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class Sort {
  static readonly type = '[Proposed Orders] Sort';

  constructor(public sort: Sorted) {}
}

export class RemoveFilterParam {
  static readonly type = '[Proposed Orders] Remove Filter Param';
}

export class RemoveAllFilters {
  static readonly type = '[Proposed Orders] Remove All Filters';
}

export class Paginate {
  static readonly type = '[Proposed Orders] Paginate';

  constructor(public pagination: PageEventV2) {}
}

export class ResetPagination {
  static readonly type = '[Proposed Orders] Reset Pagination';

  constructor(public page: number = 0) {}
}

export class ColumnsSelected {
  static readonly type = '[Proposed Orders] Columns Selected';

  constructor(public selection: ColumnSelectionV3) {}
}

export class HideColumn {
  static readonly type = '[Proposed Orders] Hide Column';

  constructor(public columnKey: string) {}
}

export class ToggleFilter {
  static readonly type = '[Proposed Orders] Toggle Filter';
}

export class AddFilterParamNoReload {
  static readonly type = '[Proposed Orders] Add Filter Param No Reload';

  constructor(
    public param: ColumnFilterParam[],
    public reload?: boolean
  ) {}
}

export class ResetPaginationAndLoadData {
  static readonly type = '[Proposed Orders] Reset Pagination And Load Data';
}
